import React from 'react'
import { Link, StaticQuery } from 'gatsby'
import _ from 'lodash'
import Layout from '../components/layout'
import PageFooter from '../components/PageFooter'

import mapMarkdownRemarks from '../components/mapMarkdownRemarks'
import FullWidthHeroTextWithImage from '../components/FullWidthHeroTextWithImage'
import CenteredParagraph from '../components/CenteredParagraph'

/**
 * TODO: Add a banner explaining that we are phasing out the program.
 *       also give stats on the programs and point to new programs that
 *       we are implementing.
 */
const Programs = ({ programs, heading, contentBlocks, language }) => {
  
  return (
    <Layout language={language}>
      <CenteredParagraph
        node={contentBlocks}
        title={heading && heading.title}
        subtitle={heading && heading.subtitle}
        blockStyle={{ backgroundColor: '#f4f4f8' }}
      />
      <div style={{ backgroundColor: 'white' }}>
        {programs.map((program, index) => (
          <FullWidthHeroTextWithImage
            node={program}
            slug={program.slug}
            title={program.title}
            excerpt={
              <div dangerouslySetInnerHTML={{ __html: program.excerpt }} />
            }
            image={_.get(program.images, '[0]')}
            subtitle={program.subtitle}
            linkTo={program.link}
            align={index % 2 ? 'right' : 'left'}
            blockStyle={{ backgroundColor: 'white' }}
          />
        ))}
      </div>
      <PageFooter language={language} />
    </Layout>
  )
}

export const query = graphql`
  query {
    programs: allMarkdownRemark(
      filter: { frontmatter: { tags: { in: "#program-entry" } } }
    ) {
      edges {
        node {
          ...AllMarkdownRemarkDataFragment
        }
      }
    }

    miscContentBlocks: allMarkdownRemark(
      filter: { frontmatter: { tags: { in: "#misc-content-blocks" } } }
    ) {
      edges {
        node {
          ...AllMarkdownRemarkDataFragment
        }
      }
    }
  }
`

export default ({ data, pageContext }) => {

  const language = _.get(pageContext, 'language')
  const programs = mapMarkdownRemarks(data.programs.edges || [], language)
  const order = [
    'programs/scholarship',
    'programs/book-club',
    'programs/mobile-lab',
  ]
  const sortedPrograms = _.sortBy(programs, item =>
    _.findIndex(order, slug => {
      return item.link.includes(slug)
    })
  )

  const miscContentBlocks = _.first(mapMarkdownRemarks(data.miscContentBlocks.edges || [], language))
  const programsHeading = _.find(
    _.get(miscContentBlocks, "entries"),
    entry => entry.tags.includes('#our-programs-headings')
  )

  return <Programs language={language} contentBlocks={miscContentBlocks} heading={programsHeading} programs={sortedPrograms} />
}
