import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Row, Col } from 'react-flexbox-grid'

import withAdminLinks from '../utils/withAdminLinks';

import Image from './Image'

import './PageFooter.css'
import PromiseLink from './PromiseLink'

class FullWidthHeroTextWithImage extends React.Component {
  render() {
    const { excerpt, blockStyle, title, linkTo, image, align } = this.props

    const alignLeft = align !== 'right'

    let columns = [
      <Col md="6" sm="12" style={{ padding: 0 }}>
        <Row>
          <Col
            xs={12}
            className={alignLeft ? 'text-right' : 'text-left'}
            style={{ paddingLeft: 30, paddingRight: 30 }}
          >
            <h1 style={{ fontSize: 43 }}>{title}</h1>
            {excerpt}
            <PromiseLink to={linkTo} primary/>
          </Col>
        </Row>
      </Col>,
      <Col md="6" sm="12" className="hidden-xs">
        <Image height="400px" width="100%" mode="fill" src={image} />
      </Col>,
    ]

    if (!alignLeft) {
      columns = _.reverse(columns)
    }

    return (
      <Row
        center="xs"
        className="FullWidthHeroTextWithImage flex-center__y"
        style={_.assign({}, blockStyle)}
      >
        {columns}
      </Row>
    )
  }
}

FullWidthHeroTextWithImage.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.node,
  blockStyle: PropTypes.object,
}

export default withAdminLinks(FullWidthHeroTextWithImage)
